import * as React from "react";

// React
import { useState, useEffect } from "react";
import { AdvancedImage, responsive, lazyload } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { Link } from "gatsby";
import Seo from "../components/seo";

// SVG
import Logo from "../images/logo/logo.inline.svg";
import LogoLetters from "../images/logo/logo-letters.inline.svg";
import World from "../images/home/world.inline.svg";
import Light from "../images/home/light.inline.svg";
import StepGoal from "../images/home/stepGoal.inline.svg";

// Heroicons
import {
  ArrowNarrowRightIcon,
  ArrowSmDownIcon,
  VolumeOffIcon,
  VolumeUpIcon,
} from "@heroicons/react/outline";

// lotties
import PlayButton from "../images/home/lottie/play-button/PlayButton";

// Shared Components
import VideoModal from "../components/shared/video-modal/VideoModal";
import TextModal from "../components/shared/modal/Modal";
import LoadingButton from "../components/shared/loading-button/LoadingButton";
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";

// Services
import { contactSubmission } from "../services/contactAPI";

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [largeScreen, setLargeScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isMusic, setIsMusic] = useState(false);
  const myAudioRef = React.useRef();

  //ADD LISTENER TO CHECK THE SCREEN SIZE
  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
    } else {
      setLargeScreen(true);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  function toggleVideoOpen() {
    setIsOpen(!isOpen);
  }

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const FourTenetsImage = cld
    .image("v1647288436/Aritae/Home/mountain-graphic_nvw3qg.jpg")
    .quality("auto");
  const InspireTheWorld = cld
    .image(
      "v1648055930/Aritae/Home/home-woman-arms-outstretched-in-nature_sbbflp.jpg"
    )
    .quality("auto");
  const UnleashYourPotential = cld
    .image("v1648055817/Aritae/Home/home-woman-climbing-mountain_ifov3v.jpg")
    .quality("auto");
  const Trevor = cld
    .image("v1646054959/Aritae/Home/home-trevor_vl8nn2.jpg")
    .quality("auto");
  const LeadYourself = cld
    .image(
      "v1648055774/Aritae/Home/home-girl-running-soccer-team-high-fives_acvhek.jpg"
    )
    .quality("auto");
  const PlatformImage = cld
    .image(
      "v1646054895/Aritae/Home/home-platform-coaches-players-collage_oijsqf.jpg"
    )
    .quality("auto");
  const Maddy = cld
    .image("v1646252103/Aritae/Home/maddy_a8d37j.jpg")
    .quality("auto");
  const Julie = cld
    .image(
      "v1650562379/Aritae/Home/aritae-parent-julie-testimonial-positive_swbz3h.jpg"
    )
    .quality("auto");
  const Brennan = cld
    .image("v1646054790/Aritae/Home/home-brennan-testimonial_a32bfm.jpg")
    .quality("auto");
  const SignUpBanner = cld
    .image("v1654782303/Aritae/Home/Aritae-Home-Page-Mosiac-Large_odk5wc.jpg")
    .quality("auto");
  const SignUpBannerMobile = cld
    .image("v1654782302/Aritae/Home/Aritae-Home-Page-Mosiac-Small_ypexej.jpg")
    .quality("auto");

  //email sent from contact form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const email = event.target.email.value;

    let postBody = JSON.stringify({
      Email: email,
      PageName: "Home",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      console.log(data);
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsModalOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in ARITAE! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsModalOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  // Modal
  function toggleModalOpen() {
    setIsModalOpen(!isModalOpen);
  }

  // Katherin Video Modal
  function toggleKatherineModal() {
    setIsModal2Open(!isModal2Open);
  }

  function toggleJerrysMusic(event) {
    event.preventDefault();

    if (isMusic) {
      myAudioRef.current.pause();
      setIsMusic(!isMusic);
      console.log("got here!");
    } else {
      myAudioRef.current.play();
      setIsMusic(!isMusic);
    }
  }

  return (
    <div id="root">
      <Seo title="ARITAE Self-Leadership Academy & Community" />
      <audio
        ref={myAudioRef}
        src="https://aritae.s3.ca-central-1.amazonaws.com/ES_Team+Spirit+-+Josef+Falkensko%CC%88ld.mp3"
      />
      <Header />
      <div className={"relative bg-gray-100"}>
        {/* Header Video Modal */}
        <VideoModal
          isOpen={isOpen}
          ariaHideApp={false}
          close={toggleVideoOpen}
          link="https://res.cloudinary.com/kirkwork/video/upload/v1654891327/Aritae/Home/ARITAE_LEADYOURSELF_TOPMIDDLE_FINAL_zmjmin.mp4"
        />
        {/* END Header Modal */}

        {/* Katherine Video Modal */}
        <VideoModal
          isOpen={isModal2Open}
          close={toggleKatherineModal}
          link="https://res.cloudinary.com/kirkwork/video/upload/v1648127564/Aritae/Home/Katherine_Video_g42qkj.mov"
        />
        {/* END Katherine Modal */}

        <TextModal
          isOpen={isModalOpen}
          close={toggleModalOpen}
          icon={icon}
          description={description}
        />
        {/* START Hero Section */}
        <div className={"relative px-16"}>
          <div className={"w-auto justify-center items-center"}>
            <div className="absolute flex-1 top-0 inset-0">
              <div
                className={
                  "absolute inset-0 flex bg-gradient-to-t from-slate-600"
                }
              />
              <video
                autoPlay
                muted
                playsInline
                loop
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              >
                <source
                  src="https://res.cloudinary.com/kirkwork/video/upload/q_auto/v1646164815/Aritae/Shared/Home-Header-Medium_gy5rev.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="relative mx-auto max-w-xl min-h-screen">
              <div
                className={
                  "flex flex-col min-h-screen items-center justify-center space-y-12"
                }
              >
                <div className={"flex flex-col items-center justify-center"}>
                  <Logo fill={"white"} className={"h-32 w-auto mx-auto pb-2"} />
                  <LogoLetters
                    fill={"white"}
                    className={"h-auto w-48 mx-auto"}
                  />
                </div>
                <div
                  role={"button"}
                  tabIndex={0}
                  onKeyDown={(e) => toggleVideoOpen(e)}
                  onClick={() => {
                    toggleVideoOpen();
                  }}
                  className={"relative cursor-pointer"}
                >
                  <PlayButton />
                </div>
                <p
                  className={
                    "text-center text-white font-bold mt-5 text-4xl max-w-2xl"
                  }
                >
                  INSPIRING YOUNG PEOPLE TO REACH THEIR POTENTIAL
                </p>
              </div>
            </div>
          </div>
          <button
            className="absolute right-5 bottom-5"
            onClick={toggleJerrysMusic}
          >
            {isMusic ? (
              <VolumeOffIcon className="h-5 w-5 text-white" />
            ) : (
              <VolumeUpIcon className="h-5 w-5 text-white" />
            )}
          </button>
        </div>
        {/* END Hero Section */}

        {/* START Aritae IS Pragraph */}
        <div className="relative bg-slate-600">
          <div className="mx-auto pb-16 px-16 max-w-5xl">
            <div className="text-center space-y-6">
              <h1 className="text-4xl  text-white pt-16">
                <span className="font-bold">ARITAE</span> IS
              </h1>
              <p className="text-lg text-white leading-10">
                A community of like-hearted and like-minded individuals with an
                inspirational mindset that helps guide young people to find the
                clarity, focus, and power needed to reach their potential.
              </p>
            </div>
            <div className="text-center space-y-6">
              <h1 className="text-4xl  text-white mt-12">
                <span className="font-bold">ARITAE</span> COACHES
              </h1>
              <p className="text-lg text-white leading-10">
                Take a proactive approach to guiding young people to become
                self-leaders and live a life filled with ARITAE’s 4 Tenets:
                Love. Happiness. Significance. Success.
              </p>
            </div>
          </div>
        </div>

        {/* END Aritae IS Paragraph */}

        {/* START What is 3 Column */}
        <div className={"relative bg-slate-600 py-24"}>
          <div
            className={
              "grid max-w-7xl mx-auto grid-cols-1 md:grid-cols-3 gap-16 px-16"
            }
          >
            <div
              className={
                "flex flex-col items-center space-y-10 text-center max-w-xs mx-auto"
              }
            >
              <World />
              <p className={"text-white font-bold text-xl max-w-2xl"}>
                OUR DESTINY
              </p>
              <p className={"text-white text-md"}>
                To help create a world wherein young people can reach their
                potential and find love, happiness, significance, and success.
              </p>
            </div>
            <div
              className={
                "flex flex-col items-center space-y-10 text-center max-w-xs mx-auto"
              }
            >
              <Light />
              <p className={"text-white font-bold text-xl max-w-2xl"}>
                OUR CAUSE
              </p>
              <p className={"text-white text-md"}>
                To unleash the power of young people to create a more inspired
                world.
              </p>
            </div>
            <div
              className={
                "flex flex-col items-center space-y-10 text-center max-w-xs mx-auto"
              }
            >
              <StepGoal />
              <p className={"text-white font-bold text-xl max-w-2xl"}>
                OUR PURPOSE
              </p>
              <p className={"text-white text-md"}>
                We are totally committed to young people and their quest to
                reach their potential in all areas of their lives.
              </p>
            </div>
          </div>
        </div>
        {/* END What is 3 Column */}

        {/* START Aritae Pragraph */}
        <div className={"relative py-24 px-16"}>
          <div
            className={
              "flex flex-col mx-auto items-center justify-center space-y-10"
            }
          >
            <div className={"flex flex-col items-center justify-center"}>
              <Logo className={"h-32 w-auto mx-auto pb-2 fill-slate-600"} />
              <LogoLetters className={"h-auto w-48 mx-auto fill-slate-600"} />
            </div>
            <h1 className="text-4xl text-slate-600 pt-8">
              WHERE <span className="font-bold">ARITAE</span> COMES FROM
            </h1>
            <div className="flex flex-col space-y-4">
              <p className={"text-black text-xl max-w-4xl text-center"}>
                {" "}
                ARITAE is the evolution of Jerry Morrissey’s 35+ years of
                studying and working in the areas of personal growth,
                development, leadership and athletic coaching. ARITAE began as a
                self-leadership program developed and written by Jerry in
                2003-04 for young people called “Coaching for Kids.”  
              </p>
              <p className={"text-black text-xl max-w-4xl text-center"}>
                {" "}
                The word ARITAE comes from the Greek word “arete”, which means….
                fulfillment of purpose and the act of living up to one’s
                potential. While climbing in the Teton Mountain Range in Wyoming
                many years ago, Jerry felt the same sense of Inspirational love
                he feels when working with young people, guiding them to become
                self-leaders and unleash their potential.
              </p>
            </div>

            <a href={"/about"} className={"flex justify-center text-center"}>
              <p
                className={
                  "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                }
              >
                Learn More About ARITAE
              </p>
            </a>
          </div>
        </div>
        {/* END Aritae Paragraph */}

        {/* START 3 IMAGES SECTION */}
        <div
          className={
            "relative py-12 max-w-sm md:max-w-lg lg:max-w-7xl mx-auto lg:px-16"
          }
        >
          <ul className="grid grid-cols-1 gap-y-8 lg:grid-cols-3 gap-x-3">
            <li className="relative max-w-xs mx-auto sm:max-w-none">
              <div className="relative group block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                <figure className="absolute inset-0">
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={LeadYourself}
                    alt="girl running through her team giving high-fives on a field"
                    className="h-full w-full object-cover"
                  />
                  <div
                    className={"absolute inset-0 flex bg-overlay opacity-30"}
                  />
                  <figcaption
                    className={
                      "absolute bottom-5 left-5 right-5 text-white font-extrabold text-3xl text-center"
                    }
                  >
                    LEAD YOURSELF
                  </figcaption>
                  <div className={"opacity-0 lg:hover:opacity-100"}>
                    <div className={"absolute inset-0 flex bg-slate-600"}>
                      <div
                        className={
                          "relative flex flex-col justify-center items-center px-8"
                        }
                      >
                        <h1
                          className={
                            "relative text-white font-extrabold text-lg lg:text-sm xl:text-lg text-center"
                          }
                        >
                          Self-Awareness is the most important attribute to
                          becoming a self-leader. At the ARITAE Self-Leadership
                          Academy, our top priority is guiding young people to
                          discover who they are from the inside out.
                        </h1>
                        <div
                          role={"button"}
                          tabIndex={0}
                          onKeyDown={() => toggleKatherineModal()}
                          className="cursor-pointer"
                          onClick={() => toggleKatherineModal()}
                        >
                          <h1
                            className={
                              "relative pt-4 text-white font-extrabold underline text-lg lg:text-sm xl:text-lg text-center"
                            }
                          >
                            Click here to watch an ARITAE player talk about
                            self-leadership in middle school
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </figure>
              </div>
              <div className="mt-2 visible lg:invisible">
                <p className="text-sm font-medium text-gray-900 pointer-events-none">
                  Self-Awareness is the most important attribute to becoming a
                  self-leader. At the ARITAE Self-Leadership Academy, our top
                  priority is guiding young people to discover who they are from
                  the inside out.
                </p>
                <div
                  role={"button"}
                  tabIndex={0}
                  onKeyDown={() => toggleKatherineModal()}
                  onClick={() => toggleKatherineModal()}
                >
                  <h1
                    className={
                      "text-sm font-medium text-gray-900 mt-2 underline "
                    }
                  >
                    Watch More.
                  </h1>
                </div>
              </div>
            </li>
            <li className="relative max-w-xs mx-auto sm:max-w-none">
              <div className="relative group block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                <figure className="absolute inset-0">
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={UnleashYourPotential}
                    alt="woman rock climbing outdoors over a forest"
                    className="h-full w-full object-cover object-top"
                  />
                  <div
                    className={"absolute inset-0 flex bg-overlay opacity-30"}
                  />
                  <figcaption
                    className={
                      "absolute bottom-5 left-5 right-5 text-white font-extrabold text-3xl text-center"
                    }
                  >
                    UNLEASH YOUR POTENTIAL
                  </figcaption>
                  <div className={"opacity-0 lg:hover:opacity-100"}>
                    <div className={"absolute inset-0 flex bg-slate-600"}>
                      <h1
                        className={
                          "relative flex justify-center items-center px-8 text-white font-extrabold text-lg lg:text-sm xl:text-lg text-center"
                        }
                      >
                        Finding your “Eternal Power” is the essence of ARITAE.
                        It is one of the driving forces you will discover on
                        your path to success at the ARITAE Self- Leadership
                        Academy.
                      </h1>
                    </div>
                  </div>
                </figure>
              </div>
              <p className="mt-2 visible lg:invisible text-sm font-medium text-gray-900 pointer-events-none">
                Finding your “Eternal Power” is the essence of ARITAE. It is one
                of the driving forces you will discover on your path to success
                at the ARITAE Self- Leadership Academy.
              </p>
            </li>
            <li className="relative max-w-xs mx-auto sm:max-w-none">
              <div className="relative group block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                <figure className="absolute inset-0">
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={InspireTheWorld}
                    alt="woman with arms raised triumphantly in the air"
                    className="h-full w-full object-cover object-top"
                  />
                  <div
                    className={"absolute inset-0 flex bg-overlay opacity-30"}
                  />
                  <figcaption
                    className={
                      "absolute bottom-5 left-5 right-5 text-white font-extrabold text-3xl text-center"
                    }
                  >
                    INSPIRE THE WORLD
                  </figcaption>
                  <div className={"opacity-0 lg:hover:opacity-100"}>
                    <div className={"absolute inset-0 flex bg-slate-600"}>
                      <h1
                        className={
                          "relative flex justify-center items-center px-8 text-white font-extrabold text-lg lg:text-sm xl:text-lg text-center"
                        }
                      >
                        ARITAE is an inspirational love shared by a community of
                        like hearted individuals that helps guide them to find
                        the clarity, focus and eternal power needed to reach
                        their potential.
                      </h1>
                    </div>
                  </div>
                </figure>
              </div>
              <p className="mt-2 visible lg:invisible text-sm font-medium text-gray-900 pointer-events-none">
                ARITAE is an inspirational love shared by a community of like
                hearted individuals that helps guide them to find the clarity,
                focus and eternal power needed to reach their potential.
              </p>
            </li>
          </ul>
        </div>
        {/* END 3 IMAGES SECTION */}

        {/* START Jerry Quote */}
        <div className="bg-mustard bg-opacity-10">
          <div className={"relative py-24"}>
            <div
              className={
                "flex flex-col mx-auto px-16 items-center justify-center space-y-10"
              }
            >
              <p
                className={
                  "text-slate-600 font-bold text-4xl max-w-2xl text-center"
                }
              >
                At ARITAE we believe that every young person has the potential
                to make a significant impact on the world.
              </p>
              <div className={"flex flex-row items-center"}>
                <div className={"h-1 w-5 bg-black mr-5"} />
                <span>
                  <p
                    className={
                      "text-black font-bold text-md md:text-xl max-w-2xl"
                    }
                  >
                    JERRY MORRISSEY
                  </p>
                  <p
                    className={
                      "text-mustard font-bold text-md md:text-md max-w-2xl"
                    }
                  >
                    FOUNDER, CEO, CHIEF INSPIRATIONAL OFFICER
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* END Jerry Quote */}

        {/* START WHAT WE DO Pragraph */}
        <div className="mx-auto my-36 px-16 max-w-7xl">
          <div className="text-center space-y-10">
            <h1 className="text-4xl  text-slate-600">
              WHAT <span className="font-bold">WE DO</span>
            </h1>
            <p className="text-lg text-slate-600 leading-10">
              Utilizing our proprietary ARITAE program within our global,
              inspirational ARITAE Self-Leadership Academy platform we select
              and train people to become highly qualified coaches to guide young
              people to find the clarity, focus, and power to unleash their
              potential, become self-leaders, and live a life filled with
              ARITAE’s four tenets: Love. Happiness. Significance. Success.
            </p>
          </div>
        </div>
        {/* END WHAT WE DO Pragraph */}

        {/* START Mountain Graphic */}
        <div className={"hidden md:contents relative"}>
          <div className={"relative mt-24"}>
            <figure className="relative">
              <AdvancedImage
                plugins={[responsive({ steps: 200 })]}
                cldImg={FourTenetsImage}
                alt="mountain covered in snow with yellow path that reads love, happiness, significance, success along it leading to a flag at the top"
                className="h-full w-full object-contain"
              />
              <figcaption className={"absolute bottom-5 right-5"}>
                <div className={"flex flex-col items-center justify-center"}>
                  <Logo fill={"white"} className={"h-12 w-auto mx-auto pb-2"} />
                  <LogoLetters
                    fill={"white"}
                    className={"h-auto w-24 mx-auto"}
                  />
                </div>
              </figcaption>
            </figure>
            <div className="absolute inset-0 px-16">
              <div className={"h-screen mx-auto max-w-7xl"}>
                <div>
                  <h1 className="text-left max-w-7xl tracking-tight hidden md:contents text-4xl">
                    <span className="text-slate-600">
                      THE <span className={"font-extrabold"}>FOUR TENETS</span>{" "}
                      OF{" "}
                      <p className={"font-extrabold"}>
                        ARITAE SELF-LEADERSHIP ACADEMY
                      </p>
                    </span>
                  </h1>
                  <div className={"flex flex-row gap-10"}>
                    <a
                      href={"/academy"}
                      className={
                        "flex flex-row items-center mt-5 gap-5 hover:gap-3"
                      }
                    >
                      <p
                        className={
                          "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                        }
                      >
                        Start Your Journey
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END Mountain Graphic */}

        {/* START Mobile 4 Tenets */}
        <div
          className="md:hidden pt-24"
          style={{
            backgroundImage: `url(${"https://res.cloudinary.com/kirkwork/image/upload/v1646054599/Aritae/Home/home-Mountain-Plain_2x_yaza8g.jpg"})`,
            backgroundPosition: "bottom",
          }}
        >
          <div className="md:hidden pt-6">
            <h1 className="text-center max-w-7xl mx-4 tracking-tight text-4xl">
              <span className="text-slate-600">
                THE <span className={"font-extrabold"}>FOUR TENETS</span> OF{" "}
                <p className={"font-extrabold"}>
                  ARITAE SELF-LEADERSHIP ACADEMY
                </p>
              </span>
            </h1>
            <div className={"flex flex-row justify-center gap-10 mb-24"}>
              <Link
                to="/academy"
                className={"flex flex-row items-center mt-5 gap-5 hover:gap-3"}
              >
                <p
                  className={
                    "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                  }
                >
                  Start Your Journey
                </p>
              </Link>
            </div>
          </div>
          {/*Start of the Tenet Blocks */}
          <div className={"max-w-md mx-auto space-y-4 pb-24 pr-2 pl-2"}>
            <div className="md:hidden rounded-xl p-8 text-center bg-white bg-opacity-80 text-slate-600">
              <h4 className="font-extrabold text-2xl pb-2">LOVE</h4>
              <p>
                We believe we can all be winners in life if we begin the game of
                life looking within ourselves to determine what is in our heart
                and what we feel is more important to us.
              </p>
            </div>
            <div className="md:hidden flex justify-center">
              <ArrowSmDownIcon className={"h-5"} />
            </div>
            <div className="md:hidden rounded-xl p-8 text-center bg-white bg-opacity-80 text-slate-600">
              <h4 className="font-extrabold text-2xl pb-2">HAPPINESS</h4>
              <p>
                When we look inside our hearts, we will be destined to live a
                purposeful life; one filled with happiness.
              </p>
            </div>
            <div className="md:hidden flex justify-center">
              <ArrowSmDownIcon className={"h-5"} />
            </div>
            <div className="md:hidden rounded-xl p-8 text-center bg-white bg-opacity-80 text-slate-600">
              <h4 className="font-extrabold text-2xl pb-2">SIGNIFICANCE</h4>
              <p>
                By living a life filled with love and happiness, our hearts will
                be filled with a sense of purpose, pride, and joy
                (significance).
              </p>
            </div>
            <div className="md:hidden flex justify-center">
              <ArrowSmDownIcon className={"h-5"} />
            </div>
            <div className="md:hidden rounded-xl p-8 text-center bg-white bg-opacity-80 text-slate-600 ">
              <h4 className="font-extrabold text-mustard text-2xl pb-2">
                SUCCESS
              </h4>
              <p>
                If we play the game leading from our heart (love), which in turn
                gives us a sense of purpose (happiness), we will feel an amazing
                sense of pride and joy (significance). We then are prepared to
                make self-determined plans that will lead us to achieving out
                goals and dreams (success).
              </p>
            </div>
          </div>
        </div>
        {/* END Mobile 4 Tenets */}

        {/* START App Section */}
        <div className="py-12 md:py-24">
          <div className="relative">
            <div className={"relative max-w-7xl mx-auto py-12 px-16"}>
              <div
                className={"relative grid grid-cols-1 lg:grid-cols-2 gap-10"}
              >
                {/* First section */}
                <h1 className="contents lg:hidden text-center tracking-tight text-4xl text-slate-600">
                  <span className="block pt-24">
                    HOW<span className="font-extrabold"> WE DO IT</span>
                  </span>
                </h1>
                <div
                  className={
                    "relative h-full w-full max-w-sm md:max-w-lg lg:max-w-none mx-auto"
                  }
                >
                  <div className={"relative h-full w-full"}>
                    <div
                      className={
                        "absolute inset-3 -right-3 -bottom-3 bg-slate-600"
                      }
                    />
                    <div
                      className={
                        "relative h-full w-full justify-center items-center"
                      }
                    >
                      <AdvancedImage
                        plugins={[responsive(200)]}
                        cldImg={PlatformImage}
                        alt="Coaches and Players talking with each other over a video call with an image of the ARITAE app in between them."
                        className="h-full w-full object-cover object-top aspect-square"
                      />
                    </div>
                  </div>
                </div>
                {/* Second section */}
                <div className={"relative flex justify-center items-center "}>
                  <div>
                    <h1 className="hidden lg:contents tracking-tight text-4xl text-slate-600">
                      <span className="block pt-24">
                        HOW<span className="font-extrabold"> WE DO IT</span>
                      </span>
                    </h1>
                    <br />
                    <div className="flex flex-col space-y-4">
                      <p className="text-left font-serif text-black md:text-md lg:text-lg">
                        ARITAE has embraced technology to create a platform that
                        supports young people and coaches. With the guidance of
                        the ARITAE coach the young person (player) will find the
                        clarity, focus and power to unleash their potential and
                        become self-leaders.
                      </p>
                      <p className="text-left font-serif text-black md:text-md lg:text-lg">
                        With a proven track record of guiding young people to
                        live a life filled with ARITAE’s 4 – tenets, Love,
                        Happiness, Significance and Success, ARITAE Academy is
                        now a fully digital platform that leverages technology
                        to connect young people and ARITAE coaches in a
                        community that fulfills our Destiny, Cause, and Purpose.
                      </p>
                    </div>

                    <br></br>
                    <div className={"flex flex-row gap-10"}>
                      <Link
                        to="/coming-soon"
                        className={
                          "flex flex-row items-center gap-5 hover:gap-3"
                        }
                      >
                        <p
                          className={
                            "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                          }
                        >
                          How The Platform Works
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* END App Section */}

        {/* START Testimonials */}
        <div className="py-24 bg-mustard bg-opacity-10">
          <div className={"relative max-w-7xl mx-auto"}>
            <div className={"pb-16"}>
              <div className={"flex items-center"}>
                <h1
                  className={
                    "mx-auto text-slate-600 max-w-xl text-center text-4xl"
                  }
                >
                  SUCCESS <span className="font-extrabold">STORIES</span>
                </h1>
              </div>
              {/* Link to testimnoials */}
              <div className={"flex flex-row justify-center gap-10"}>
                <a
                  href={"/testimonials"}
                  className={
                    "flex flex-row items-center pt-4 gap-5 hover:gap-3"
                  }
                >
                  <p
                    className={
                      "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                    }
                  >
                    More Stories
                  </p>
                </a>
              </div>
            </div>
            <ul className="grid mx-16 grid-cols-1 gap-y-8 sm:grid-cols-2 lg:grid-cols-4 gap-x-3">
              <li className="relative max-w-xs mx-auto sm:max-w-none">
                <div className="relative group block w-full aspect-w-1 aspect-h-1 overflow-hidden">
                  <figure className="absolute inset-0">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Trevor}
                      alt="Trevor Diamond united states navy headshot"
                      className="h-full w-full object-cover"
                    />
                    <div
                      className={"absolute inset-0 flex bg-overlay opacity-30"}
                    />
                    <figcaption
                      className={
                        "absolute left-2 bottom-12 text-white text-3xl font-extrabold "
                      }
                    >
                      Trevor
                    </figcaption>
                    <figcaption
                      className={
                        "absolute left-2 bottom-4 text-white text-xl font-extrabold"
                      }
                    >
                      US Naval Academy
                    </figcaption>
                  </figure>
                </div>
                <p className="mt-2 block text-sm text-gray-900 pointer-events-none">
                  “ARITAE gave me the tools that I needed to turn around my
                  academic struggles. In little time, I had the confidence to be
                  a leader amongst my peers.”
                </p>
              </li>
              <li className="relative max-w-xs mx-auto sm:max-w-none">
                <div className="relative group block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                  <figure className="absolute inset-0">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Maddy}
                      alt="Maddy Morrissey coaching players with a clipboard on the field"
                      className="h-full w-full object-cover object-top"
                    />
                    <div
                      className={"absolute inset-0 flex bg-overlay opacity-30"}
                    />
                    <figcaption
                      className={
                        "absolute left-2 bottom-12 text-white text-3xl font-extrabold "
                      }
                    >
                      Maddy
                    </figcaption>
                    <figcaption
                      className={
                        "absolute left-2 bottom-4 text-white text-xl font-extrabold"
                      }
                    >
                      LAX & ARITAE Coach
                    </figcaption>
                  </figure>
                </div>
                <p className="mt-2 block text-sm font-medium text-gray-900 pointer-events-none">
                  "Middle school was hard for me. With so many transitions, I
                  felt out of control and lost. ARITAE Academy helped me find
                  myself. "
                </p>
              </li>
              <li className="relative max-w-xs mx-auto sm:max-w-none">
                <div className="relative group block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                  <figure className="absolute inset-0">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Julie}
                      alt="man with beard and glasses smiling at the camera"
                      className="h-full w-full object-cover object-top"
                    />
                    <div
                      className={"absolute inset-0 flex bg-overlay opacity-30"}
                    />
                    <figcaption
                      className={
                        "absolute left-2 bottom-12 text-white text-3xl font-extrabold "
                      }
                    >
                      Julie
                    </figcaption>
                    <figcaption
                      className={
                        "absolute left-2 bottom-4 text-white text-xl font-extrabold"
                      }
                    >
                      ARITAE Parent
                    </figcaption>{" "}
                  </figure>
                </div>
                <p className="mt-2 block text-sm font-medium text-gray-900 pointer-events-none">
                  “Our son has enjoyed working with ARITAE and has made
                  consistent progress. I think he has benefited from setting
                  goals and working in different ways to achieve them”
                </p>
              </li>
              <li className="relative max-w-xs mx-auto sm:max-w-none">
                <div className="relative group block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                  <figure className="absolute inset-0">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Brennan}
                      alt="Brennan Buell playing lacrosse outdoors"
                      className="h-full w-full object-cover"
                    />
                    <div
                      className={"absolute inset-0 flex bg-overlay opacity-30"}
                    />
                    <figcaption
                      className={
                        "absolute left-2 bottom-12 text-white text-3xl font-extrabold "
                      }
                    >
                      Brennan
                    </figcaption>
                    <figcaption
                      className={
                        "absolute left-2 bottom-4 text-white text-xl font-extrabold"
                      }
                    >
                      D1 Athlete
                    </figcaption>{" "}
                  </figure>
                </div>
                <p className="mt-2 block text-sm font-medium text-gray-900 pointer-events-none">
                  “Like a lot of knucklehead boys, I had a ton of gas but no
                  direction. ARITAE was like goal therapy where I visualize my
                  goals. Then it was up to me to achieve them.”
                </p>
              </li>
            </ul>
          </div>
        </div>
        {/* END Testimonials */}

        {/* START Become a Section */}
        <div className="max-w-7xl mx-auto pt-24 pb-12 px-16">
          <div className="grid grid-rows-3 text-4xl divide-y divide-gray-300">
            <Link to="/academy/players">
              <div className={"flex flex-row py-8 justify-between hover:mr-2"}>
                <p className={"text-mustard"}>
                  BECOME A <b>PLAYER</b>
                </p>
                <ArrowNarrowRightIcon className={"h-8 inline text-mustard"} />
              </div>
            </Link>

            <Link to="/academy/coaches">
              <div className={"flex flex-row py-8 justify-between hover:mr-2"}>
                <p className={"text-navy"}>
                  BECOME A <b>COACH</b>
                </p>
                <ArrowNarrowRightIcon className={"h-8 inline text-navy"} />
              </div>
            </Link>

            <Link to="/academy/parents">
              <div className={"flex flex-row py-8 justify-between hover:mr-2"}>
                <p className={"text-forest"}>
                  ARE YOU A <b>PARENT</b>?
                </p>
                <ArrowNarrowRightIcon className={"h-8 inline text-forest"} />
              </div>
            </Link>
          </div>
        </div>
        {/* END Become a Section*/}

        {/* START Sign Up Form */}
        <div className={"flex h-screen justify-center items-center mt-24"}>
          <div className="absolute z-10 flex h-screen w-screen overflow-hidden">
            <div className={"absolute inset-0 opacity-75 bg-stone-500"} />
            <AdvancedImage
              plugins={[lazyload()]}
              cldImg={largeScreen ? SignUpBanner : SignUpBannerMobile}
              alt="boy playing piano and girl playing saxophone on stage in front of a large audience "
              className="w-full h-full object-cover"
            />
          </div>

          <div className="relative z-20 mx-auto max-w-3xl text-center md:px-5">
            <div className="py-10">
              <h4 className="text-4xl font-extrabold text-white">
                ARE YOU READY TO REACH YOUR POTENTIAL?
              </h4>
            </div>

            <form
              className="space-y-6 row-auto py-5 p-4  max-w-3xl xs:m-12 md:m-0"
              action="#"
              method="POST"
              onSubmit={handleSubmit}
            >
              <div className="mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                  <div className="mt-1 md:col-span-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="Email"
                      required
                      className="text-center md:text-left w-full px-5 py-3 text-gray-500 border-transparent placeholder-gray-500 bg-gray-100 font-sm md:font-md"
                    />
                  </div>

                  <div className="mt-1">
                    {loading ? (
                      <LoadingButton type={"email"} />
                    ) : (
                      <button
                        type="submit"
                        className="w-full flex justify-center px-5 py-3 border border-transparent rounded shadow-sm text-sm text-white font-bold bg-black hover:bg-gray-800"
                      >
                        SIGN UP
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* END Sign Up Form */}
      </div>
      <Footer />
    </div>
  );
}
