
import lottie from 'lottie-web';
import React, { useEffect}  from "react";
import playButton from './play-button.json';

export default function PlayButton() {

    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector("#play-button"),
            renderer: 'svg',
            loop: true,
            autoplay:true,
            animationData: playButton ,
        })

    }, []);

    return (
        <div className={'relative h-20 md:h-32 lg:h-44'} id="play-button"></div>
    );

}

